// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HOME_TITLE: "Offrez le livre idéal à un proche !",
  HOME_SUBTITLE:
    "Trouvez le livre parfait en précisant un titre, auteur ou thème qu'il adore et renseignez son profil.",
  HOME_INPUT: "Titre, auteur, thème ",
  LEGAL_MENTIONS: "Mentions légales",
  POLITIQUE: "Politique de Confidentialité",
  TITLE_COUP_DE_COEUR: "Nos coups de cœur",
  TITLE_FOOTER_B2B: "Découvrez les catalogues",
  TITLE_FOOTER_GLEEPH: "Découvrez encore plus de lectures sur",
  ADD_BOOK:
    "Ajoutez un autre livre, mot-clé, auteur ou cliquez pour découvrir vos idées lecture sur-mesure.",
  GET_RECO: "SUIVANT",
  START_RECO: "C'EST PARTI",
  SELECT_AGE_TITLE: "À qui souhaitez-vous offrir un livre ?",
  SELECT_AGE_SUBTITLE:
    "Pour commencer, indiquez l’âge de la personne qui va recevoir ce cadeau :",
  SELECT_TYPE_TITLE: {
    theme: "Maintenant, dites-nous en plus sur ses goûts",
    author: "Quels sont ses auteurs favoris ?",
    book: "Quels sont ses livres préférés ?",
  },
  SHARE_LIST_RECO: "partager la liste",
  SHARE_TITLE: "Recevoir ma liste d’idées cadeaux",
  SHARE_SUB_TITLE: "Donnez un nom à votre liste et recevez-la par e-mail :",
  SHARE_LINK_EXPORT: "partager le lien",
  SHARE_PDF_EXPORT: "exporter en pdf",
  SELECT_TYPE_SUBTITLE: {
    book: "Sélectionnez un ou plusieurs livres grâce à la recherche ci-dessous :",
    author:
      "Sélectionnez un ou plusieurs auteurs grâce à la recherche ci-dessous :",
    theme:
      "Sélectionnez un ou plusieurs thèmes dans la liste ci-dessous, ou utilisez la recherche :",
  },
  PLACEHOLDER_INPUT: {
    book: "Titre de livre...",
    author: "Nom d’auteur...",
    theme: "Thème, genre, mot clé, ...",
  },
  RECO_TITLE: "Voici nos idées de cadeaux pour cette personne :",
  TITLE_END: "Le festival est terminé !",
  SUBTITLE_END:
    "Pendant toute la durée du FIBD, le groupe 1 et Gleeph vous ont permis de découvrir des recommandations de lecture sur mesure. Découvrez maintenant tous les catalogues des éditions 1 et 2.",
  LOAD_BOOKS: "Nous calculons vos recommandations",
  SHARE_BUTTON: "recevoir mes idées cadeaux",
  TITLE_NO_RECO: "Mmh, vous nous posez une colle !",
  SUBTITLE_NO_RECO:
    "Lancez une nouvelle recherche ou inspirez-vous de nos coups de cœur !",
  BACK_HOME_SCREEN: "Retour à la recherche",
  REGLEMENT_CONCOURS: "Réglement concours",
  TITLE_RUBRIQUE_RECOS: "Nos meilleurs idées cadeaux",
  TITLE_RUBRIQUE_SERIES: "Dans la même série",
  TITLE_RECOS_AUTHOR: "Pour un fan de",
  TITLE_RECOS_THEME: "Pour un fan de",
  TITLE_RECOS_BOOK: "Si votre proche a aimé ",
  CONCOUR_BUTTON: "participer au jeu concours",
  CONCOURS_TITLE: "Participez à notre jeu concours",
  CONCOURS_SUBTITLE: "2 livres à gagner par mois.",
  CONCOURS_REGISTERED_TITLE: "Merci pour votre participation !",
  CONCOURS_SUB_REGISTERED_TITLE_1:
    "Nous vous recontacterons après tirage au sort si vous êtes l'un des gagnants.",
  CONCOURS_SUB_REGISTERED_TITLE_2:
    "Vous pourrez aussi tenter à nouveau votre chance le mois prochain.",
  CONCOURS_EMAIL_SEND_TITLE: "Oups, vous avez déjà participé !",
  CONCOURS_SUB_EMAIL_SEND_TITLE_1:
    "Une seule participation est autorisée chaque mois par personne.",
  CONCOURS_SUB_EMAIL_SEND_TITLE_2: "Retentez votre chance le mois prochain!",
  CONCOURS_BANDEAU_1: "Prix RELAY des Voyageurs Lecteurs",
  CONCOURS_BANDEAU_2: "2 iPhone, 2 Polaroïd, 1 an de livres à gagner",
  CONCOURS_BANDEAU_3: "du 2 avril au 2 Juin 2024",
  VOTED: "Voter",
  CLOSED: "Fermer",
  CONCOUR_MODAL_1:
    " Votez et tentez de gagner 2 iPhone,  2 Polaroid et 1 an de livres",
  PARTICIPATE_CONCOURS: "participer au vote",
  TITLE_FOOTER_B2B_1: "Découvrez encore plus de livres",
  TITLE_FOOTER_B2B_2: "à offrir sur le site d’Hugo Publishing",
  REFINING_TEXT: "Précisez le profil de votre proche",
  REFINING_AGE: "Son âge :",
  REFINING_GENDER: "Son genre :",
  REFINING_VALIDATE: "C’est parti !",
  GET_MY_GIFT_IDEAS: "recevoir mes idées cadeaux",
  RGPD_MAIL_TEXT_1:
    "HUGO PUBLISHING et F-451 collectent et traitent les données à caractère personnel de l’Utilisateur avec l’assistance de leurs prestataires, afin d’adresser les recommandations à l’Utilisateur.",
  RGPD_MAIL_TEXT_2:
    "HUGO PUBLISHING collecte et traite les données à caractère personnel de l’Utilisateur avec l’assistance de F-451, le cas échéant, afin de lui adresser la lettre d’information HUGO PUBLISHING. Pour exercer ses droits d’accès, de rectification, d’opposition, de limitation, de suppression et de portabilité des Données Personnelles, l’Utilisateur peut s’adresser à : dpo@gleeph.net.",
  RGPD_MAIL_TEXT_3:
    "Les demandes relatives spécifiquement à l’inscription à la lettre d’information HUGO PUBLISHING doivent être adressées à marketing.hugo@hugopublishing.fr",
  RGPD_MAIL_TEXT_4: "Pour plus d’informations, consultez la ",
  REFINING_RECAP_START: "Profil : ",
  REFINING_RECAP_MIDDLE: " de ",
  REFINING_RECAP_END: " ans",
  MASCULIN: "homme",
  FEMININ: "femme",
};
