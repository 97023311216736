import text from "../utils/lang/lang";
import cst from "../utils/constant";

const MentionsLegales = () => {
  return (
    <div className="reglementConcoursContainer">
      <div
        onClick={() => window.open(`/${cst.ID_STORE}/legalNotice`)}
        className="mentionLegalesPolice"
      >
        {text.LEGAL_MENTIONS}
      </div>
    </div>
  );
};

export default MentionsLegales;
