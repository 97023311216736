import logoSoleil from "../images/b2b/logoHugo.png";
import text from "../utils/lang/lang";
import helpers from "../utils/apiHelper";

const FooterB2B = () => {
  return (
    <div className="containerFooterB2B">
      <div className="cataloguePolice">{text.TITLE_FOOTER_B2B_1}</div>
      <div className="cataloguePolice">{text.TITLE_FOOTER_B2B_2}</div>
      <div className="containerImageFooterB2B">
        <div
          onClick={() => {
            helpers.fetchTrackSearch("click_on_client_catalog", null);
            window.open(
              "https://www.hugopublishing.fr/?mtm_source=gleeph&amp;mtm_medium=webapp&amp;mtm_campaign=webappnoel2023"
            );
          }}
          style={{ cursor: "pointer" }}
        >
          <img src={logoSoleil} alt="tag" className="B2BCatalogLogoSize" />
        </div>
      </div>
    </div>
  );
};

export default FooterB2B;
