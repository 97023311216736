import React from "react";
import logo from "../images/Icon.svg";

//Default composant pour render une couverture en fond jaune avec le titre
const DefaultCover = (props) => {
  let { title, imgs, type } = props;

  if (imgs === undefined || imgs === null) {
    return (
      <div
        className={
          type === "bookDetail"
            ? "bookcover-default-container  itemImgBookDetail"
            : "bookcover-default-container  itemImg"
        }
      >
        <div className="bookcover-default-border">
          <span className="bookcover-default-title-police">{title}</span>
        </div>
      </div>
    );
  } else if (imgs.split("/")[5] === "-300") {
    if (title === undefined) {
      return (
        <div className="defaultCover itemImg">
          <img src={logo} alt="defaultCover" />
        </div>
      );
    } else {
      return (
        <div
          className={
            type === "bookDetail"
              ? "bookcover-default-container  itemImgBookDetail"
              : "bookcover-default-container  itemImg"
          }
        >
          <div className="bookcover-default-border">
            <span className="bookcover-default-title-police">{title}</span>
          </div>
        </div>
      );
    }
  } else {
    return <img className="itemImg" src={imgs} alt="cover" />;
  }
};

export default DefaultCover;
