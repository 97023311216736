import React from "react";
import text from "../utils/lang/lang";

const RefiningRecap = ({ ageRef, genderRef }) => {
  return (
    <div className="refiningPanelText">
      {text.REFINING_RECAP_START}
      <strong>{genderRef === "men" ? text.MASCULIN : text.FEMININ}</strong>
      {text.REFINING_RECAP_MIDDLE}
      <strong>
        {ageRef}
        {text.REFINING_RECAP_END}
      </strong>
    </div>
  );
};

export default RefiningRecap;
