import text from "../utils/lang/lang";
import cst from "../utils/constant";

const PolitiqueDeConfident = () => {
  return (
    <div className="politiqueContainer">
      <div
        onClick={() => window.open(`/${cst.ID_STORE}/politique`)}
        className="mentionLegalesPolice"
      >
        {text.POLITIQUE}
      </div>
    </div>
  );
};

export default PolitiqueDeConfident;
