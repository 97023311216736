import React, { useEffect, useState } from "react";
import helpers from "../utils/apiHelper";
import { clearImg, clearImgs } from "../utils/functions";
import ResultWithReco from "../components/ResultWithReco";
import ResultWithoutReco from "../components/ResultWithoutReco";
import { BookConsumerHook } from "../stores/bookStore";
import FooterB2B from "../puresComponents/FooterB2B";
import Back from "../puresComponents/Back";
import cst from "../utils/constant";

const Datas = () => {
  const [
    {
      bookReco,
      bookSearch,
      bookAuthor,
      bookSerie,
      authorRef,
      glidRef,
      themeRef,
      search,
      typeOfSearch,
      needLoads,
      searchTracking,
      resultAutocompletion,
      subject,
      originWebsiteParent,
      tracking_consent,
    },
    dispatch,
  ] = BookConsumerHook();

  const params = new URLSearchParams(window.location.search);
  const [getResponseAPI, setGetResponseAPI] = useState(false);
  const [coupDeCoeur, setCoupDeCoeur] = useState([]);
  const [loading, setLoading] = useState(needLoads ? true : false);
  const refined = params.get("refined") === "true";

  useEffect(() => {
    // Check Store and use it if not empty
    function addStateAndStore(
      book_reco,
      book_search,
      book_author,
      book_series,
      authorRef,
      glidRef,
      themeRef,
      search,
      typeOfSearch
    ) {
      const bk_search = book_search === undefined ? [] : book_search;
      const auth_search = authorRef === undefined ? [] : authorRef;
      if (
        book_reco != null &&
        book_reco !== "Mot Invalide" &&
        book_reco.length !== 0
      ) {
        dispatch({
          type: "addData",
          newBookReco: book_reco.filter((book) => book.imgs),
          newBookSearch: bk_search,
          newBookAuthor: book_author.filter((book) => book.imgs),
          newBookSerie: book_series.filter((book) => book.imgs),
          newAuthorRef: auth_search,
          newGlidRef: glidRef,
          newThemeRef: themeRef,
          newSearch: search,
          newTypeOfSearch: typeOfSearch,
          newNeedLoads: false,
        });
      } else {
        dispatch({
          type: "setNeedLoads",
          setNeedLoads: false,
        });
      }
      setGetResponseAPI(true);
      setLoading(false);
    }

    if (needLoads) {
      const book = params.get("book");
      const author = params.get("author");
      const theme = params.get("theme");

      const age = params.get("age");
      const gender = params.get("gender");
      if (!loading) setLoading(true);
      const fetchData = async () => {
        const res = await helpers.fetchGetDatas(
          book,
          author,
          theme,
          age,
          gender,
          searchTracking,
          resultAutocompletion,
          subject,
          "true",
          {
            timeout: 5000,
          }
        );

        if (typeof res === "object" && "recos" in res) {
          const recos_w_validurl = await clearImgs(res["recos"]["recos"], true);

          let same_author_w_validurl = [];
          let same_serie_w_validurl = [];

          if (res["books_by_author"].length !== 0) {
            same_author_w_validurl = await clearImgs(
              res["books_by_author"]["authors_books"]
            );
          }

          if (res["books_by_serie"].length !== 0) {
            same_serie_w_validurl = await clearImgs(
              res["books_by_serie"]["series_books"]
            );
          }

          let tempSearch = book;
          let tempTypeOfSearch = "book";
          let input_book = res["recos"]["input_book"];
          if (book !== "") {
            input_book = input_book[0];
            const check_img = await clearImg(input_book.imgs);
            if (!check_img) {
              input_book.imgs = null;
            }
          }
          if (author !== "") {
            tempTypeOfSearch = "author";
            tempSearch = author;
          }
          if (theme !== "") {
            tempTypeOfSearch = "theme";
            tempSearch = theme;
          }

          addStateAndStore(
            recos_w_validurl,
            input_book,
            same_author_w_validurl,
            same_serie_w_validurl,
            author,
            book,
            theme,
            tempSearch,
            tempTypeOfSearch
          );
          dispatch({ type: "clearAutocompletionHistoric" });
        } else {
          dispatch({
            type: "setNeedLoads",
            setNeedLoads: false,
          });
          setGetResponseAPI(true);
          setLoading(false);
        }
      };
      const fetchSearchData = async () => {
        let res, tempSearch, tempTypeOfSearch, input_book;
        if (book !== "") {
          res = await helpers.fetchGetSeries(
            book,
            author,
            theme,
            age,
            gender,
            searchTracking,
            resultAutocompletion,
            subject,
            "true",
            {
              timeout: 5000,
            }
          );
        }

        if (book !== "" && typeof res === "object" && "input_book" in res) {
          tempSearch = book;
          tempTypeOfSearch = "book";
          input_book = res["input_book"];
          input_book = input_book[0];
          const check_img = await clearImg(input_book.imgs);
          if (!check_img) {
            input_book.imgs = null;
          }
        }
        if (author !== "") {
          tempTypeOfSearch = "author";
          tempSearch = author;
        }
        if (theme !== "") {
          tempTypeOfSearch = "theme";
          tempSearch = theme;
        }

        dispatch({
          type: "setBookSearch",
          newBookSearch: input_book,
          newGlidRef: book,
          newAuthorRef: author,
          newThemeRef: theme,
          newSearch: tempSearch,
          newTypeOfSearch: tempTypeOfSearch,
          newNeedLoads: false,
        });
        setGetResponseAPI(true);
        setLoading(false);
      };
      if (refined) {
        console.log("refined");
        fetchData();
      } else {
        fetchSearchData();
      }
    }
  }, [
    dispatch,
    glidRef,
    loading,
    needLoads,
    params,
    refined,
    resultAutocompletion,
    search,
    searchTracking,
    subject,
    themeRef,
    typeOfSearch,
  ]);

  function sendUserTracking(element = null, type = "from_reco_to_book_detail") {
    helpers.fetchTrackSearch(type, element);
  }

  return (
    <div>
      <section>
        <Back redirect={`/${cst.ID_STORE}`} />
        {getResponseAPI && bookReco.length === 0 && refined && !loading ? (
          <ResultWithoutReco coupDeCoeur={coupDeCoeur} />
        ) : (
          <div>
            <ResultWithReco
              bookStock={bookReco}
              loading={loading}
              typeOfSearch={typeOfSearch}
              search={search}
              bookAuthor={bookAuthor}
              bookSearch={bookSearch}
              bookSerie={bookSerie}
              glidRef={glidRef}
              authorRef={authorRef}
              themeRef={themeRef}
              sendUserTracking={sendUserTracking}
              ageRef={params.get("age")}
              genderRef={params.get("gender")}
              isRefined={refined}
            />
            <div className="separateLineStyle" />
          </div>
        )}
        <FooterB2B />
      </section>
    </div>
  );
};

export default Datas;
