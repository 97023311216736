import React, { useState, useRef } from "react";
import API from "../utils/apiHelper";
import auteurLogo from "../images/AuteurBlack.svg";
import themeLogo from "../images/TagBlack.svg";
import livreLogo from "../images/Livres.svg";
import loupe from "../images/search.svg";
import cross from "../images/close.svg";
import AutocompletionText from "../puresComponents/AutocompletionText";
import text from "../utils/lang/lang";
import cst from "../utils/constant";

const Autocompletion = (props) => {
  const { navigate, searchTracking, dispatch, focus, setFocus } = props;
  const [value, setValue] = useState("");
  const [timer, setTimer] = useState(null);
  const [showAutoCompletion, setShowAutoCompletion] = useState(false);
  const [listAutoCompletion, setListAutoCompletion] = useState([]);
  const ref_input = useRef(null);

  const borderBottomRadiusStyle = {};
  const backgroundColorStyle = {};

  if (showAutoCompletion) {
    borderBottomRadiusStyle.borderBottomLeftRadius = 0;
    borderBottomRadiusStyle.borderBottomRightRadius = 0;
  }

  if (focus) {
    backgroundColorStyle.backgroundColor = "#ffffff";
  } else {
    backgroundColorStyle.backgroundColor = "#F15046";
  }

  function handleChange(event) {
    setValue(event.target.value);
    if (event.target.value.length >= 3) {
      //Call AutoComplete API if no letter write during 250ms
      const newTimer = setTimeout(async () => {
        const res = await API.fetchAutocomplete(event.target.value);

        //Show autocompletion only if they are result
        if (!showAutoCompletion && res.length > 0) setShowAutoCompletion(true);
        //Hide autocompletion if no result
        if (showAutoCompletion && res.length === 0)
          setShowAutoCompletion(false);

        fillAutoCompletion(res, event.target.value);
      }, 350);
      setTimer(newTimer);
    } else if (event.target.value.length < 3 && showAutoCompletion) {
      setShowAutoCompletion(false);
    }

    clearTimeout(timer);
  }

  function fillAutoCompletion(resAutoCompletion, value = "") {
    try {
      var tempListAutoCompletion = resAutoCompletion.map((el, id) => (
        <div
          className="containerAutoCompletion"
          onClick={() => {
            handleSelectAutocompletion(el.id_ean != null ? el.id_ean : el.name);
            navigate(
              `/${cst.ID_STORE}/datas/?refined=false&book=${
                el.type === "book" ? el.id_ean : ""
              }&author=${el.type === "author" ? el.name : ""}&theme=${
                el.type === "theme" ? el.keyword : ""
              }`
            );
          }}
          key={id}
        >
          {el.type === "book" && (
            <img className="itemAutoCompletionImg" src={livreLogo} alt="item" />
          )}
          {el.type === "theme" && (
            <img className="itemAutoCompletionImg" src={themeLogo} alt="item" />
          )}
          {el.type === "author" && (
            <img
              className="itemAutoCompletionImg"
              src={auteurLogo}
              alt="item"
            />
          )}
          <AutocompletionText el={el} type={el.type} searchWord={value} />
        </div>
      ));

      dispatch({
        type: "autocompletionHistoric",
        newSearchTracking: [...searchTracking, value],
        newResultAutocompletion: resAutoCompletion.map((el) =>
          el.title != null ? el.title : el.name
        ),
      });
      setListAutoCompletion(tempListAutoCompletion);
    } catch {
      console.log("query has been cancelled");
    }
  }

  function handleFocus(event) {
    setFocus(true);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  }

  function handleSelectAutocompletion(subject) {
    setValue("");
    setShowAutoCompletion(false);
    setListAutoCompletion([]);
    dispatch({ type: "selectElementInAutocompletion", newSubject: subject });
  }

  function clearAutoCompletion() {
    setValue("");
    setShowAutoCompletion(false);
    setListAutoCompletion([]);
    dispatch({ type: "clearAutocompletionHistoric" });
    focusOnInput();
  }

  const focusOnInput = () => {
    ref_input.current.focus();
  };

  return (
    <div className="autocomplete">
      <input
        id={showAutoCompletion ? "myBookInput" : "myBookInputShadow"}
        ref={ref_input}
        autocomplete="off"
        type="text"
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={text.HOME_INPUT}
        className="inputPolice"
        style={{ ...borderBottomRadiusStyle, ...backgroundColorStyle }}
      />

      {value.length === 0 ? (
        <img src={loupe} className="autocomplete_svg" alt="loupe" />
      ) : (
        <img
          src={cross}
          className="autocomplete_svg"
          alt="clear"
          onFocus={handleFocus}
          onClick={() => {
            clearAutoCompletion();
          }}
        />
      )}

      {showAutoCompletion && (
        <div className="autocomplete-items">{listAutoCompletion}</div>
      )}
    </div>
  );
};
export default Autocompletion;
