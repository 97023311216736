import stockReco from "../images/stockReco.svg";
import Loading from "../puresComponents/Loading";
import TitleReco from "./TitleReco";
import RecoBooksComp from "./RecoBooksComp";
import text from "../utils/lang/lang";
import NewSearch from "../puresComponents/NewSearch";
import RefiningPanel from "./RefiningPanel";
import ShareButton from "../puresComponents/ShareButton";
import RefiningRecap from "./RefiningRecap";

const ResultWithReco = (props) => {
  const {
    bookStock,
    loading,
    typeOfSearch,
    search,
    bookAuthor,
    bookSearch,
    bookSerie,
    glidRef,
    authorRef,
    themeRef,
    ageRef,
    genderRef,
    isRefined,
  } = props;

  return (
    <div>
      <div className="resultRecoImageContainer">
        <img src={stockReco} alt="reco" />
      </div>
      {loading ? (
        <Loading text={text.LOAD_BOOKS} />
      ) : (
        <div className="resultRecoContainer">
          <TitleReco
            type={typeOfSearch}
            search={search}
            book={bookSearch}
            lenBookAuthor={bookAuthor.length}
          />
          {isRefined ? (
            <>
              {ageRef && genderRef && (
                <RefiningRecap ageRef={ageRef} genderRef={genderRef} />
              )}
              <RecoBooksComp
                listBook={bookStock}
                listBookAuthor={bookAuthor}
                listSerie={bookSerie}
                glidRef={glidRef}
                authorRef={authorRef}
                themeRef={themeRef}
                type={typeOfSearch}
              />
              <div className="separateLineStyle" />
              <div className="NewSearchContainer">
                <ShareButton glidRef={glidRef} typeOfSearch={typeOfSearch} />
                <div style={{ marginBottom: 16 }} />
                <NewSearch />
              </div>
            </>
          ) : (
            <RefiningPanel
              glidRef={glidRef}
              authorRef={authorRef}
              themeRef={themeRef}
              ageRef={ageRef}
              genderRef={genderRef}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ResultWithReco;
