export const MenIcon = ({ color = "#252525" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M12.3535 0.205078H8.7207C8.52655 0.205078 8.36914 0.362484 8.36914 0.556641V1.49414C8.36914 1.6883 8.52655 1.8457 8.7207 1.8457H9.90434L7.87461 3.87544C7.13666 3.36424 6.24109 3.06445 5.27539 3.06445C2.75127 3.06445 0.705078 5.11064 0.705078 7.63477C0.705078 10.1589 2.75127 12.2051 5.27539 12.2051C7.79952 12.2051 9.8457 10.1589 9.8457 7.63477C9.8457 6.66905 9.54591 5.7735 9.03472 5.03552L11.0645 3.00579V4.18945C11.0645 4.38361 11.2219 4.54102 11.416 4.54102H12.3535C12.5477 4.54102 12.7051 4.38361 12.7051 4.18945V0.556641C12.7051 0.362484 12.5477 0.205078 12.3535 0.205078ZM5.27539 10.5645C3.65736 10.5645 2.3457 9.25277 2.3457 7.63477C2.3457 6.01673 3.65736 4.70508 5.27539 4.70508C6.89342 4.70508 8.20508 6.01673 8.20508 7.63477C8.20508 9.25277 6.89342 10.5645 5.27539 10.5645Z"
        fill={color}
      />
    </svg>
  );
};

export const WomenIcon = ({ color = "#252525" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="13"
      viewBox="0 0 9 13"
      fill="none"
    >
      <path
        d="M8.57227 4.07227C8.57227 1.93648 6.84087 0.205078 4.70508 0.205078C2.56929 0.205078 0.837891 1.93648 0.837891 4.07227C0.837891 5.92652 2.14305 7.47572 3.88477 7.85198V8.92383H2.5957C2.40155 8.92383 2.24414 9.08123 2.24414 9.27539V10.2129C2.24414 10.407 2.40155 10.5645 2.5957 10.5645H3.88477V11.8535C3.88477 12.0477 4.04217 12.2051 4.23633 12.2051H5.17383C5.36798 12.2051 5.52539 12.0477 5.52539 11.8535V10.5645H6.81445C7.00861 10.5645 7.16602 10.407 7.16602 10.2129V9.27539C7.16602 9.08123 7.00861 8.92383 6.81445 8.92383H5.52539V7.85198C7.2671 7.47572 8.57227 5.92652 8.57227 4.07227ZM2.47852 4.07227C2.47852 2.84257 3.47538 1.8457 4.70508 1.8457C5.93477 1.8457 6.93164 2.84257 6.93164 4.07227C6.93164 5.30196 5.93477 6.29883 4.70508 6.29883C3.47538 6.29883 2.47852 5.30196 2.47852 4.07227Z"
        fill={color}
      />
    </svg>
  );
};

export const CheckBox = ({
  className = "",
  color = "#F15046",
  checked = false,
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M21.0732 1.70508H7.51552C4.3065 1.70508 1.70508 4.3065 1.70508 7.51552V21.0732C1.70508 24.2822 4.3065 26.8837 7.51552 26.8837H21.0732C24.2822 26.8837 26.8837 24.2822 26.8837 21.0732V7.51552C26.8837 4.3065 24.2822 1.70508 21.0732 1.70508Z"
        stroke={color}
        strokeWidth="1.57366"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {checked && (
        <path
          d="M8.48389 16.2311L12.4544 19.4075C12.561 19.4953 12.6859 19.5583 12.8199 19.5918C12.9539 19.6253 13.0937 19.6285 13.2291 19.6012C13.3658 19.5756 13.4954 19.5208 13.609 19.4406C13.7226 19.3603 13.8177 19.2566 13.8876 19.1364L20.1048 8.48389"
          stroke={color}
          strokeWidth="1.57366"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};
