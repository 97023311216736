import { MenIcon, WomenIcon } from "../utils/icons";

const ListGender = (props) => {
  const { checked, setGender, gender } = props;

  const typeProposition = [
    {
      text: "Homme",
      id: "men",
      Icon: MenIcon,
    },
    {
      text: "Femme",
      id: "women",
      Icon: WomenIcon,
    },
  ];

  return (
    <div className="listElementGenderContainer">
      {typeProposition.map((el) => (
        <ElementGender
          checked={checked}
          setGender={setGender}
          text={el.text}
          id={el.id}
          GenderIcon={el.Icon}
          gender={gender}
          key={el.id}
        />
      ))}
    </div>
  );
};

const ElementGender = (props) => {
  const { checked, setGender, text, id, gender, GenderIcon } = props;
  const isSelected = gender === id ? true : false;
  return (
    <div
      onClick={() => {
        setGender(id);
      }}
      className={
        checked
          ? isSelected
            ? "elementGenderContainer selectedGenderContainer"
            : "elementGenderContainer"
          : "elementGenderContainer elementGenderContainerDisabled"
      }
    >
      <GenderIcon color={isSelected ? "white" : "#252525"} />
      <span
        className={
          isSelected
            ? "elementAuthorThemeText selectedGenderText"
            : "elementAuthorThemeText"
        }
      >
        {text}
      </span>
    </div>
  );
};

export default ListGender;
