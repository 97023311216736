import arts from "../images/Arts.svg";
import BD from "../images/BD.svg";
import Cartes from "../images/Cartes.svg";
import Documentation from "../images/Documentation.svg";
import Droit from "../images/Droit.svg";
import Encyclopédies from "../images/Encyclopédies.svg";
import Esoterisme from "../images/Esoterisme.svg";
import Geographie from "../images/Geographie.svg";
import Histoire from "../images/Histoire.svg";
import Informatique from "../images/Informatique.svg";
import Jeunesse from "../images/Jeunesse.svg";
import Litterature_Generale from "../images/Litterature_Generale.svg";
import Livres_pratiques from "../images/Livres_pratiques.svg";
import Management from "../images/Management.svg";
import Medecine from "../images/Medecine.svg";
import Parascolaire from "../images/Parascolaire.svg";
import Religion from "../images/Religion.svg";
import Sans from "../images/Sans.svg";
import Sciences_eco from "../images/Sciences_eco.svg";
import Sciences_hum from "../images/Sciences_hum.svg";
import Sciences_po from "../images/Sciences_po.svg";
import Sciences from "../images/Sciences.svg";
import Scolaire from "../images/Scolaire.svg";
import Techniques from "../images/Techniques.svg";
import { v4 as uuidv4 } from "uuid";
import { client_variables } from "../client_variables";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ENV: process.env.REACT_APP_ENV,
  URL_API: process.env.REACT_APP_NODE_URL,
  TEMP_USERID: uuidv4(),
  BOOKSCREEN_API: {
    prod: "https://lotbjctfz0.execute-api.eu-west-1.amazonaws.com/v1/b2b/book/",
    preprod:
      "https://hjcct82vhl.execute-api.eu-west-1.amazonaws.com/v1/b2b/book/",
    dev: "https://hjcct82vhl.execute-api.eu-west-1.amazonaws.com/v1/b2b/book/",
  },
  WORK_PICTURE: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/",
  PEOPLE_PICTURE: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/people/",
  FIN_LIEN: "-300",
  CLIL_ICON: {
    3435: Litterature_Generale,
    3722: Jeunesse,
    3000: Scolaire,
    3017: Parascolaire,
    3259: Droit,
    3771: BD,
    3177: Management,
    3305: Sciences_eco,
    3283: Sciences_po,
    3080: Sciences_hum,
    3365: Esoterisme,
    3345: Religion,
    3193: Informatique,
    3069: Techniques,
    3051: Sciences,
    3165: Medecine,
    3802: Livres_pratiques,
    3667: arts,
    3801: Documentation,
    3377: Histoire,
    3395: Geographie,
    3898: Cartes,
    3418: Encyclopédies,
    "0000": Sans,
    999999: Sans,
  },
  PDV_RELAY: {
    322172: "EST CENTRAL SNCF",
    323121: "P-LYON VOIE A SNCF",
    323188: "P-LYON VOIE 13 SNCF",
    324228: "MONTPARNASSE VOIE 11 SNCF",
    325266: "NORD CENTRAL VOIE 13 SNCF",
    326215: "ST LAZARE VOIE 1 SNCF",
    340182: "BORDEAUX DEP NIV 0 SNCF",
    340695: "LYON P DIEU BILLET SNCF",
    341164: "NANTES NORD SNCF",
    341636: "STRASBOURG HALL DEP SNCF",
    342717: "AVIGNON COURTINE TGV",
    352230: "CDG2 BD ZSCH",
    352534: "CDG2E RELAY AIR PARIS ZSD",
    352542: "CDG2 F1 ZSCH",
    352567: "CDG2 F2  ZSCH",
    352740: "CDG2 F DEPART ZP",
    361253: "ORLY 4 F ZSD",
    362061: "ORLY 1 A ZP",
    362202: "ORLY 3 ZP",
    362209: "ORLY 3 D ZSCH",
    373415: "NANTES ATLANTIQUE ZP",
    373605: "MARSEILLE T2 ZCP",
    373449: "NICE 2 MODULE 1",
    373233: "LYON STEX T1B ZR OCCITANE",
    324244: "MONTPARNASSE VOIE 24 SNCF",
    352831: "CDG2 E S4 ZSD",
    352526: "CDG2 E ZP",
    373746: "TOULOUSE BLAGNAC HC ZSCH",
    341446: "RENNES NIVEAU 2 SNCF",
    350215: "CDG1 SATELLITES",
    323154: "P-LYON VOIE N SNCF",
    325159: "NORD VOIE 3 SNCF",
    373258: "LYON ST-EXUPERY T1B ZP",
    321034: "AUSTERLITZ SNCF",
    352633: "CDG2 E S3 CENTR. ZSD",
    362103: "ORLY 2 C ZP",
    373316: "MARSEILLE T1 HB ZCV",
    340554: "LILLE FLANDRES BUISSES",
    340679: "LYON P DIEU VILLETTE SNCF",
    341743: "TOULOUSE DEPART SNCF",
    362343: "ORLY 2 BOX 1 ZSCH",
    341693: "TOULON SNCF",
    323337: "P-LYON MEDIT CHALON SNCF",
    373696: "TOULOUSE BLAGNAC AERO",
    340174: "BORDEAUX DEP NIV -1 SNCF",
    353029: "CDG2 G ZSCH",
    340471: "GRENOBLE SNCF",
    362079: "ORLY 1 A ZSCH",
    341842: "TOURS SNCF",
    373548: "NICE 2 MODULE 2 ZSCH",
  },
  ID_STORE:
    window.location.pathname.split("/")[1] === ""
      ? client_variables.DEFAULT_CATALOG
      : window.location.pathname.split("/")[1],
  CLIENT_NAME: client_variables.CLIENT_NAME,
  DEFAULT_CATALOG: client_variables.DEFAULT_CATALOG,
  CLIENT_MAIL: client_variables.CLIENT_MAIL,
  COLOR_TOP_BAR: client_variables.COLOR_TOP_BAR,
  COLOR_BUTTON: client_variables.COLOR_BUTTON,
};
