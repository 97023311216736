import React from "react";
import FooterB2B from "../puresComponents/FooterB2B";

export default function LegalNotice() {
  return (
    <div>
      <section>
        <div className="legalNoticeContainer">
          <div className="legalNotice">MENTIONS LEGALES</div>
          <p className="legalNoticeSubtitle">EDITEUR DU SITE :</p>
          <p className="legalNoticeText">
            Vous êtes actuellement connecté au site{" "}
            <a href="https://hugo.gleeph.com">https://hugo.gleeph.com</a> édité
            par les sociétés:
            <ul>
              <li>
                F-451, SAS au capital de 335 178 euros, dont le siège social est
                situé 28 Bis Avenue de Strasbourg - 17 340 Châtelaillon-Plage,
                immatriculée au Registre du Commerce et des Sociétés de La
                Rochelle sous le numéro 808 534 465
              </li>
              <li>
                et HUGO PUBLISHING, SAS au capital de 2 487 000 € dont le siège
                social est situé 34-36 rue La Pérouse, 75116 Paris, immatriculée
                au Registre du Commerce et des Sociétés de Paris sous le numéro
                814 543 351.
              </li>
            </ul>
            <br />
            Pour toute remarque sur le fonctionnement du site, écrivez à
            l'administrateur du site :{" "}
            <a href="mailto:contact@gleeph.net">contact@gleeph.net</a>
          </p>
          <p>
            <span className="legalNoticeSubtitle">
              DIRECTEUR DE LA PUBLICATION :
            </span>{" "}
            Guillaume DEBAIG (F-451) et Arthur de Saint-Vincent (HUGO
            PUBLISHING).
          </p>
          <p className="legalNoticeSubtitle"> HEBERGEMENT :</p>
          <p className="legalNoticeText">
            Ce site est hébergé par la société Google Cloud France SARL, 8 Rue
            de Londres, 75009 PARIS.
          </p>
          <p className="legalNoticeText">
            Les serveurs de Google Cloud sont situés en Belgique.
          </p>
          <p className="legalNoticeText">
            L'utilisateur en ayant accès à ce site s'engage à se conformer aux
            présentes conditions d'utilisation.
          </p>
          <p className="legalNoticeSubtitle">
            DROITS DE PROPRIETE INTELLECTUELLE ET INDUSTRIELLE :
          </p>
          <p className="legalNoticeText">
            Les marques, visuels et logos figurant sur ce site sont déposés
            et/ou ou propriété de F-451 ou ses partenaires. Leur mention
            n'accorde en aucune manière une licence ou un droit d'utilisation
            quelconque desdites marques, qui ne peuvent donc être utilisées sans
            le consentement préalable et écrit de F-451, de HUGO PUBLISHING ou
            de leurs partenaires.
            <br />
            <br />
            L'ensemble des informations présentes sur ce site peut être
            téléchargé, reproduit, imprimé sous réserve de :<br />
            - n'utiliser de telles informations qu'à des fins personnelles et en
            aucune manière à des fins commerciales;
            <br />
            - ne pas modifier de telles informations ;<br />
            - reproduire sur toutes copies la mention des droits d'auteur de
            F-451, HUGO PUBLISHING ou de leurs partenaires.
            <br />
            <br />
            Toute autre utilisation est strictement interdite sans autorisation
            préalable et écrite de F-451 ou HUGO PUBLISHING.
          </p>
          <p className="legalNoticeSubtitle">CREDITS :</p>
          <p className="legalNoticeText">
            Visuels : FIGS SARL, 17 Rue des Panoyaux, 75020 Paris
          </p>
          <p className="legalNoticeSubtitle">RESPONSABILITÉ :</p>
          <p className="legalNoticeText">
            L'ensemble des informations accessibles via www.gleeph.net est
            fourni en l'état.
            <br />
            <br />
            F-451 et HUGO PUBLISHING ne donnent aucune garantie et n'assument
            aucune responsabilité relative à l'usage, la copie, la transmission
            et à toute utilisation des informations, de quelque nature qu'elles
            soient, contenues sur le site
            <a href="https://hugo.gleeph.com">https://hugo.gleeph.com</a>. F-451
            et HUGO PUBLISHING ne sont responsables ni de l'exactitude, ni des
            erreurs, ni des omissions contenues sur ce site. L'utilisateur est
            seul responsable de l'utilisation de telles informations. F-451 et
            HUGO PUBLISHING ne pourront être responsables pour quelque dommage
            que ce soit résultant d'une information contenue sur ce site.
            L'utilisateur s'engage à ne transmettre sur ce site aucune
            information pouvant entraîner une responsabilité civile ou pénale et
            s'engage à ce titre à ne pas divulguer via ce site des informations
            illégales, contraires à l'ordre public, aux bonnes mœurs ou
            diffamatoires.
          </p>
          <p className="legalNoticeSubtitle">LIENS HYPERTEXTES :</p>
          <p className="legalNoticeText">
            L'insertion par un Utilisateur de liens hypertextes vers toute
            partie du Site{" "}
            <a href="https://hugo.gleeph.com">https://hugo.gleeph.com</a> est
            est interdite, sauf autorisation préalable et écrite des sociétés
            F-451 et HUGO PUBLISHING. Le site
            <a href="https://hugo.gleeph.com">https://hugo.gleeph.com</a>
            contient des liens hypertextes vers d'autres sites. F-451 et HUGO
            PUBLISHING ne sauraient, cependant, être tenus responsables du
            contenu des dits sites.
          </p>
        </div>
        <div className="separateLineStyle" />
        <FooterB2B />
      </section>
    </div>
  );
}
