import { Routes, Route, Navigate } from "react-router-dom";
import Search from "../screens/Search";
import Datas from "../screens/Datas";
import AuthorBooks from "../screens/AuthorBooks";
import SeriesBooks from "../screens/SeriesBooks";
import RecoBooks from "../screens/RecoBooks";
import BookDetail from "../screens/BookDetail";
import LegalNotice from "../screens/LegalNotice";
import cst from "../utils/constant";
import MailScreen from "../screens/MailScreen";
import Politique from "../screens/Politique";

function App() {
  return (
    <Routes>
      <Route path="/:id_store/" element={<Search />} />
      <Route path="/:id_store/datas" element={<Datas />} />
      <Route path="/:id_store/datas/author" element={<AuthorBooks />} />
      <Route path="/:id_store/datas/serie" element={<SeriesBooks />} />
      <Route path="/:id_store/datas/reco" element={<RecoBooks />} />
      <Route path="/:id_store/legalNotice" element={<LegalNotice />} />
      <Route path="/:id_store/politique" element={<Politique />} />
      <Route path="/:id_store/datas/:id_reco" element={<BookDetail />} />
      <Route path="/:id_store/sendMail" element={<MailScreen />} />
      <Route path="*" element={<Navigate to={`/` + cst.DEFAULT_CATALOG} />} />
    </Routes>
  );
}

export default App;
