import BookList from "./BookList";
import logoGift from "../images/Birthday.svg";
import text from "../utils/lang/lang";

const RecoBooksComp = (props) => {
  const {
    listBook,
    listBookAuthor,
    listSerie,
    glidRef,
    authorRef,
    themeRef,
    type,
  } = props;
  if (listBook.length > 0) {
    return <RecoBookOnly listBook={listBook} type={type} />;
  }

  return <div></div>;
};

const RecoBookOnly = (props) => {
  const { listBook, type } = props;
  return (
    <div>
      <div className="separateLineStyle" />
      <div className="containerListResultWoMargin">
        <div>
          {type !== "theme" && (
            <div style={{ marginBottom: 16 }}>
              <div>
                <span className="midTextReco">
                  <div className="logoJaimeContainer">
                    <img src={logoGift} alt="logoGift" className="imageJaime" />
                    {text.TITLE_RUBRIQUE_RECOS}
                  </div>
                </span>
              </div>
            </div>
          )}
          {listBook.length > 0 && <BookList listBook={listBook} />}
        </div>
      </div>
    </div>
  );
};

export default RecoBooksComp;
