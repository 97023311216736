import React from "react";
import text from "../utils/lang/lang";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import ListGender from "./ListGender";
import { CheckBox } from "../utils/icons";
import { convertAgeGenderToCatalog } from "../utils/functions";

const PrettoSlider = styled(Slider)({
  color: "#F15046",
  height: 12,
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#F150464D",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#F15046",
    boxShadow:
      "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow:
          "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontFamily: "Century Gothic Paneuropean",
    fontWeight: 600,
    top: 50,
    backgroundColor: "unset",
    color: "#252525",
    "&:before": {
      display: "none",
    },
  },
});

const DisabledPrettoSlider = styled(Slider)({
  color: "#B2B2B2",
  height: 12,
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#2525252F",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#B2B2B2",
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontFamily: "Century Gothic Paneuropean",
    fontWeight: 600,
    top: 50,
    backgroundColor: "unset",
    color: "#B2B2B2",
    "&:before": {
      display: "none",
    },
  },
});

const RefiningPanel = ({ glidRef, authorRef, themeRef, ageRef, genderRef }) => {
  const [checked, setChecked] = React.useState(true);
  const [gender, setGender] = React.useState(genderRef ? genderRef : "men");
  const [age, setAge] = React.useState(ageRef ? parseInt(ageRef) : 18);

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleChangeAge = (event) => {
    setAge(event.target.value);
  };

  return (
    <div className="refiningContainer">
      <div
        className={`refiningPanelContainer ${
          checked && "refiningPanelContainerChecked"
        }`}
      >
        <div className="refiningPanelCheckBoxContainer">
          <div onClick={() => handleChange()}>
            <CheckBox
              className="refiningPanelCheckBox"
              checked={checked}
              color={checked ? "#F15046" : "#B2B2B2"}
            />
          </div>
          <span
            className="refiningPanelText"
            style={{
              color: checked ? "#252525" : "#B2B2B2",
            }}
          >
            {text.REFINING_TEXT}
          </span>
        </div>

        <>
          <div className="refiningPanelSelectAge">
            <span
              className="refiningPanelTextChoice"
              style={{
                color: checked ? "#595959" : "#B2B2B2",
              }}
            >
              {text.REFINING_AGE}
            </span>
            <Box sx={{ width: "auto" }}>
              {checked ? (
                <PrettoSlider
                  defaultValue={18}
                  step={1}
                  max={99}
                  value={typeof age === "number" ? age : 0}
                  onChange={(event, value) =>
                    handleChangeAge({ target: { value } })
                  }
                  valueLabelDisplay="on"
                  valueLabelFormat={(value) => value + " ans"}
                />
              ) : (
                <DisabledPrettoSlider
                  disabled
                  defaultValue={18}
                  step={1}
                  max={99}
                  value={typeof age === "number" ? age : 0}
                  onChange={(event, value) =>
                    handleChangeAge({ target: { value } })
                  }
                  valueLabelDisplay="on"
                  valueLabelFormat={(value) => value + " ans"}
                />
              )}
            </Box>
          </div>
          <div className="refiningPanelSelectGender">
            <span
              className="refiningPanelTextChoice"
              style={{
                color: checked ? "#595959" : "#B2B2B2",
              }}
            >
              {text.REFINING_GENDER}
            </span>
            <ListGender
              checked={checked}
              gender={checked ? gender : null}
              setGender={setGender}
            />
          </div>
        </>
      </div>
      <a
        className="refiningButtonValidate"
        href={`/${convertAgeGenderToCatalog(
          checked ? age : null,
          checked ? gender : null
        )}/datas/?refined=true&book=${glidRef}&author=${authorRef}&theme=${themeRef}&age=${
          checked ? age : ""
        }&gender=${checked ? gender : ""}`}
      >
        {text.REFINING_VALIDATE}
      </a>
    </div>
  );
};

export default RefiningPanel;
