import React from "react";
import FooterB2B from "../puresComponents/FooterB2B";

export default function Politique() {
  return (
    <section>
      <div className="legalNoticeContainer">
        <div className="legalNotice">Politique de Confidentialité</div>
        <div className="center">https://hugo.gleeph.com</div>
        <br />

        <div className="legalNoticeSubtitle">
          GESTION DES DONNEES PERSONNELLES
        </div>
        <p className="legalNoticeSubtitle">Définitions</p>
        <p className="legalNoticeText">
          « Données Personnelles » : Désignent toute information relative à
          l’Utilisateur permettant de l’identifier de manière directe ou
          indirecte confiée à F-451 et/ou à HUGO PUBLISHING « Utilisateur » :
          Désigne la personne physique ou morale qui consulte le site internet
          https://hugo.gleeph.com .
        </p>
        <p className="legalNoticeSubtitle">Object</p>
        <p className="legalNoticeText">
          Les présentes ont pour objet d’informer l’Utilisateur, sur les
          traitements des Données Personnelles effectués par F-451 et/HUGO
          PUBLISHING sur le site internet https://hugo.gleeph.com.
        </p>
        <p className="legalNoticeSubtitle">Responsable du Traitement :</p>
        <p className="legalNoticeText">
          F-451 et HUGO PUBLISHING agissent en qualité de co-responsables du
          traitement pour la finalité d’envoi de recommandations à
          l’Utilisateur.
        </p>
        <p className="legalNoticeText">
          Coordonnées de F-451 : 28 Bis Avenue de Strasbourg - 17 340
          Châtelaillon-Plage
        </p>
        <p className="legalNoticeText">
          Coordonnées de HUGO PUBLISHING : 34-36 rue La Pérouse, 75116 Paris
        </p>
        <p className="legalNoticeText">
          HUGO PUBLISHING agit en qualité de responsable du traitement pour la
          finalité d’envoi des lettres d’information HUGO, F-451 agissant dans
          ce cas en qualité de sous-traitant du responsable du traitement.
        </p>
        <p className="legalNoticeSubtitle">Finalité de la collecte</p>
        <p className="legalNoticeText">
          La finalité de la collecte des Données Personnelles est de permettre
          de répondre aux demandes des Utilisateurs :
          <ul>
            <li>
              de recevoir par email les recommandations générées par le site
              https://hugo.gleeph.com/,{" "}
            </li>
            <li>de recevoir les lettres d’information de HUGO PUBLISHING,</li>
          </ul>
        </p>
        <p className="legalNoticeSubtitle">Données Personnelles Traitées</p>
        <p className="legalNoticeText">- courriel de l’Utilisateur</p>
        <p className="legalNoticeSubtitle">
          Destinataires des Données Personnelles
        </p>
        <p className="legalNoticeText">
          Les Données sont destinées à HUGO PUBLISHING et à F-451.
        </p>
        <p className="legalNoticeText">
          Sauf autorisation expresse de l’Utilisateur en ce sens, HUGO
          PUBLISHING et F-451 s’interdisent de divulguer pour tout usage, pour
          leur compte ou pour le compte d’un tiers, aux fins de
          commercialisation ou de diffusion à titre onéreux ou gratuit, les
          Données Personnelles.
        </p>
        <p className="legalNoticeText">
          Néanmoins, HUGO PUBLISHING et F-451 peuvent être amenés à divulguer
          des Données Personnelles dans le cas où elles y seraient enjointes
          dans le cadre de procédures réglementaires, judiciaires ou fiscales
          par les autorités publiques ou dans le cadre d’un transfert. Le nouvel
          acquéreur serait alors tenu aux mêmes droits et obligations que HUGO
          PUBLISHING et F-451.
        </p>
        <p className="legalNoticeText">
          Par ailleurs, les Données Personnelles peuvent être transmises ou
          collectées par les prestataires de HUGO PUBLISHING et de F-451 pour la
          finalité décrite au paragraphe « Finalité de la collecte ».
        </p>
        <p className="legalNoticeSubtitle">
          Durée de conservation des données :
        </p>
        <p className="legalNoticeText">
          F-451 conserve les données personnelles des Utilisateurs pendant une
          durée de 8 jours au plus tard à compter de la fin de la mise en ligne
          du site https://hugo.gleeph.com. HUGO PUBLISHING conserve les données
          personnelles des Utilisateurs ayant choisi de recevoir les newsletters
          Hugo Publishing pour une durée légale de 3 ans après la dernière
          action sur une newsletter.
        </p>
        <p className="legalNoticeSubtitle">
          Droits d’accès, de rectification, d’opposition, de limitation, de
          suppression, de portabilité
        </p>
        <p className="legalNoticeText">
          Pour exercer ses droits d’accès, de rectification, d’opposition, de
          limitation, de suppression et de portabilité des Données Personnelles,
          l’Utilisateur peut s’adresser à : dpo@gleeph.net. Les demandes
          relatives spécifiquement à l’inscription à la lettre d’information
          HUGO PUBLISHING doivent être adressées à
          marketing.hugo@hugopublishing.fr
        </p>
        <p className="legalNoticeSubtitle">Réclamations</p>
        <p className="legalNoticeText">
          L’Utilisateur dispose du droit d’introduire une réclamation auprès de
          la CNIL. Pour plus d’information, vous pouvez consulter le site de la
          CNIL en suivant le lien suivant https://www.cnil.fr/
        </p>
      </div>
      <div className="separateLineStyle" />
      <FooterB2B />
    </section>
  );
}
