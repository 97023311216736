import React, { useEffect, useState } from "react";
import helpers from "../utils/apiHelper";
import { useSearchParams } from "react-router-dom";
import BookList from "../components/BookList";
import serie from "../images/Serie.svg";
import { BookConsumerHook } from "../stores/bookStore";
import text from "../utils/lang/lang";
import NewSearch from "../puresComponents/NewSearch";
import Loading from "../puresComponents/Loading";
import Back from "../puresComponents/Back";
import FooterB2B from "../puresComponents/FooterB2B";

export default function SeriesBooks() {
  const [searchParams, setSearchParams] = useSearchParams();
  let book = searchParams.get("book");
  let author = searchParams.get("author");
  let theme = searchParams.get("theme");

  const [{ bookSerie }, dispatch] = BookConsumerHook();
  const [loading, setLoading] = useState(bookSerie.length > 0 ? false : true);

  useEffect(() => {
    if (bookSerie.length > 0) {
      setLoading(false);
    } else {
      callAPI();
    }
  }, []);

  async function callAPI() {
    const res = await helpers.fetchGetSeries(book, author, theme);
    addBookToList(res["books_by_serie"], res["serie_search"]);
    setLoading(false);
  }

  function addBookToList(bookSerie, serieSearch) {
    if (
      bookSerie != null &&
      bookSerie !== "Mot Invalide" &&
      bookSerie.length !== 0
    ) {
      dispatch({
        type: "addData",
        newBookReco: [],
        newBookSearch: [],
        newBookAuthor: [],
        newBookSerie: bookSerie,
        newAuthorRef: [],
        newGlidRef: "",
        newThemeRef: "",
        newSearch: "",
        newTypeOfSearch: "",
        newNeedLoads: true,
      });
    }
  }

  const RecoWithResult = () => {
    return (
      <div>
        {loading ? (
          <div className="TopWaitTextReco">
            <span className="TopTextReco forceWidth">{text.LOAD_BOOKS}</span>
            <Loading text={text.LOAD_BOOKS} />
          </div>
        ) : (
          <div className="containerListResultWoMargin">
            <div style={{ marginBottom: 16 }}>
              <div>
                <span className="midTextReco">
                  <div className="logoJaimeContainer">
                    <img className="imageJaime" src={serie} alt="item" />
                    {text.TITLE_RUBRIQUE_SERIES}
                  </div>
                </span>
              </div>
            </div>
            <div className="separateLineStyle" />
            <div style={{ marginTop: 16 }}>
              {bookSerie.length > 0 && (
                <BookList listBook={bookSerie} key={Math.random()} />
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <section>
        <Back />
        <RecoWithResult />
        <div className="separateLineStyle" />
        <div className="NewSearchContainer">
          <NewSearch />
        </div>
        <div className="separateLineStyle" />
        <FooterB2B />
      </section>
    </div>
  );
}
