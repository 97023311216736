import { useNavigate } from "react-router-dom";
import helpers from "../utils/apiHelper";
import DefaultCover from "../puresComponents/DefaultCover";
import cst from "../utils/constant";
import ClilRectangle from "../puresComponents/ClilRectangle";
import { getYear } from "../utils/functions";

function BookList(props) {
  let { listBook, type } = props;
  const navigate = useNavigate();

  return (
    <div className="ContainerBook">
      {listBook.map((book) => (
        <div
          onClick={() => {
            helpers.fetchTrackSearch("click_on_book_vertical", book.id_ean);
            book.link
              ? window.open(book.link)
              : navigate(`/${cst.ID_STORE}/datas/${book.id_ean}`);
          }}
          className="booklist-container"
          key={book.id_ean}
        >
          <Book book={book} type={type} />
        </div>
      ))}
    </div>
  );
}

const Book = (props) => {
  const { book, lite } = props;

  return (
    <div className="containerBookInfo" key={book.id_ean}>
      <DefaultCover imgs={book.imgs} />
      <div className="bookListInfoContainer">
        <p className="itemTitle">{book.title}</p>
        <p className="itemAuthor">
          {book.authors}{" "}
          {book.authors.length > 0 && book.publishing_date && "•"}{" "}
          {getYear(book.publishing_date)}
        </p>
        <ClilRectangle id={book.clil1} />
        <p className="itemEditor">
          <span className="price">{book.price} €</span>
        </p>
      </div>
    </div>
  );
};

export default BookList;
