import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Back from "../puresComponents/Back";
import ShareComp from "../components/ShareComp";
import FooterB2B from "../puresComponents/FooterB2B";
import helpers from "../utils/apiHelper";
import cst from "../utils/constant";
import text from "../utils/lang/lang";
import { BookConsumerHook } from "../stores/bookStore";

export default function MailScreen() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [listName, setListName] = useState("");
  const [emailIsSend, setEmailIsSend] = useState(false);
  const typeOfSearch = searchParams.get("typeOfSearch");
  const [isToggle, setIsToggle] = useState(false);

  const [{ bookReco, authorRef, glidRef, themeRef }] = BookConsumerHook();

  async function sendMail(mail1IsToggled) {
    const eansReco = bookReco.map((book) => book.id_ean);
    let reference = glidRef;
    if (typeOfSearch === "author") {
      reference = authorRef;
    } else if (typeOfSearch === "theme") {
      reference = themeRef;
    }

    helpers.fetchSendMailReco(
      email,
      listName,
      typeOfSearch,
      eansReco,
      reference,
      mail1IsToggled
    );
    helpers.fetchTrackSearch(
      "send_mail",
      JSON.stringify({ mail1: mail1IsToggled })
    );
    setEmailIsSend(true);
  }

  function handlerChangeEmail(name) {
    setEmail(name);
  }

  function handlerChangeNameList(name) {
    setListName(name);
  }

  function sendUserTracking(element, type) {
    helpers.fetchTrackSearch(type, element);
  }

  function clickOnToggle() {
    setIsToggle(!isToggle);
  }

  return (
    <div>
      <section>
        <Back />
        <ShareComp
          handlerChangeEmail={handlerChangeEmail}
          handlerChangeNameList={handlerChangeNameList}
          email={email}
          emailIsSend={emailIsSend}
          sendMail={sendMail}
          listName={listName}
          isToggle={isToggle}
          clickOnToggle={clickOnToggle}
        />
        <div className="separateLineStyle" />
        <FooterB2B />
        <div>
          <div className="separateLineStyle" />
          <div className="containerPoliceLegal policeLegalShare">
            <div>{text.RGPD_MAIL_TEXT_1}</div>
            <div>{text.RGPD_MAIL_TEXT_2}</div>
            <div>{text.RGPD_MAIL_TEXT_3}</div>
            <div>
              {text.RGPD_MAIL_TEXT_4}{" "}
              <span
                className="pointer textUnderline"
                onClick={() => navigate(`/${cst.ID_STORE}/politique/`)}
              >
                Politique de confidentialité.
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
