import iconBack from "../images/flecheback.svg";
import iconBackWhite from "../images/flecheback_white.svg";
import { useNavigate } from "react-router-dom";

const Back = (props) => {
  const { white, redirect } = props;
  const navigate = useNavigate();

  return (
    <div
      className="backContainerWoMargin"
      onClick={() => (redirect ? navigate(redirect) : navigate(-1))}
    >
      <img
        src={white ? iconBackWhite : iconBack}
        alt="back"
        className="backIcon"
      />
      <span className="backText" style={white ? { color: "#FFFFFF" } : null}>
        Retour
      </span>
    </div>
  );
};

export default Back;
