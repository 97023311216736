import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/sass/index.scss";
import ScrollToTop from "./router/ScrollToTop";
import RouterProvider from "./router/router";
import { BookProvider } from "./stores/bookStore";
import Header from "./puresComponents/Header";
import FooterGleeph from "./puresComponents/FooterGleeph";
import MentionsLegales from "./puresComponents/MentionsLegales";
import PolitiqueDeConfident from "./puresComponents/PolitiqueDeConfident";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BookProvider>
    <div className="backgroundGrey">
      <div className="fixWidth">
        <div className="backgroundContent">
          <Router>
            <Header withBack={false} />
            <ScrollToTop />
            <RouterProvider />
            <section style={{ paddingBottom: 80 }}>
              <div>
                <div className="separateLineStyle" />
                <FooterGleeph />
                <div className="separateLineStyle" />
                <MentionsLegales />
                <PolitiqueDeConfident />
              </div>
            </section>
          </Router>
        </div>
      </div>
    </div>
  </BookProvider>
);
