async function clearImg(img) {
  try {
    const response = await fetch(img, { method: "HEAD" });
    if (response.ok) {
      return true;
    } else {
      console.log(`Broken URL: ${img}`);
      return false;
    }
  } catch (error) {
    console.log(`Error checking URL: ${img}`);
    return false;
  }
}

async function clearImgs(res, with_limit = false) {
  //Check if Imgs URL are okay
  let res_w_validurl = [];
  await Promise.all(
    res.map(async (book) => {
      try {
        // Send a HEAD request to check the status
        const response = await fetch(book.imgs, { method: "HEAD" });
        // If the status code is in the 2xx range, consider it a success
        if (response.ok) {
          res_w_validurl.push(book);
        } else {
          console.log(`Broken URL: ${book.imgs}`);
        }
      } catch (error) {
        console.log(`Error checking URL: ${book.imgs}`);
        console.error(error);
      }
    })
  );

  //Order by distance desc
  res_w_validurl = res_w_validurl.sort((a, b) =>
    a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : 0
  );
  if (with_limit) {
    res_w_validurl = res_w_validurl.slice(0, 10);
  }
  return res_w_validurl;
}

function getYear(dateString) {
  return new Date(dateString).getFullYear();
}

function convertAgeGenderToCatalog(age, gender) {
  let catalog_name = "all_age_gender";
  if (age && gender) {
    catalog_name = gender;
    switch (true) {
      case age < 13:
        catalog_name += "_0_12";
        break;
      case age < 18:
        catalog_name += "_13_17";
        break;
      case age < 25:
        catalog_name += "_18_24";
        break;
      case age < 35:
        catalog_name += "_25_34";
        break;
      case age < 55:
        catalog_name += "_35_54";
        break;
      case age >= 55:
        catalog_name += "_55";
        break;
      default:
        console.log("age not found");
    }
    catalog_name += "_years_old";
  }
  return catalog_name;
}

export { convertAgeGenderToCatalog, clearImg, clearImgs, getYear };
