import helpers from "../utils/apiHelper";
import DefaultCover from "../puresComponents/DefaultCover";
import { useNavigate } from "react-router-dom";
import cst from "../utils/constant";
import tic from "../images/tic.svg";
import indisponible from "../images/indisponible.svg";
import { getYear } from "../utils/functions";

function BookSearch(props) {
  const { book } = props;
  const navigate = useNavigate();

  return (
    <div className="containerBookSearch backgroundBookSearchBlur">
      <div
        onClick={() => {
          helpers.fetchTrackSearch("click_on_book_search", book.id_ean);
          book.link
            ? window.open(book.link)
            : navigate(`/${cst.ID_STORE}/datas/${book.id_ean}`);
        }}
        className="subContainerBookSearch"
        key={Math.random()}
      >
        <div className="containerBlurBookSearch">
          <div
            className="blurCoverBookSearch"
            style={book.imgs ? { backgroundImage: `url(${book.imgs})` } : null}
          />
        </div>
        {book.stock === 0 && (
          <div className="containerBlurBookSearchIndisponible" />
        )}

        <div className="backgroundBookSearch " key={book.id_ean}>
          <div className="containerBookSearchInBG">
            <DefaultCover imgs={book.imgs} title={book.title} />
            <div className="itemText">
              <p className="itemTitle itemColorBookSeach">{book.title}</p>
              <p className="itemAuthor itemColorBookSeach">
                {book.authors}{" "}
                {book.authors.length > 0 && book.publishing_date && "•"}{" "}
                {getYear(book.publishing_date)}
              </p>
              <p className="itemEditor itemColorBookSeach">
                <span className="price">{book.price} €</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const BookStock = (props) => {
  const { stock } = props;
  if (stock > 0) {
    return (
      <div className="containerBottomBookSearch textBottomBookSearch colorTextBottomSearchCorrect">
        <img src={tic} className="ticBookSearch" alt="tic" /> EN STOCK
      </div>
    );
  } else {
    return (
      <div className="containerBottomBookSearch textBottomBookSearch colorTextBottomSearchIncorrect">
        <img src={indisponible} className="ticBookSearch" alt="tic" /> NON
        DISPONIBLE
      </div>
    );
  }
};

export default BookSearch;
