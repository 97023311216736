import React, { useEffect, useState } from "react";
import Autocompletion from "../components/Autocompletion";
import CoupDeCoeur from "../components/CoupDeCoeur";
import { BookConsumerHook } from "../stores/bookStore";
import { useNavigate } from "react-router-dom";
import text from "../utils/lang/lang";
import backgroundImage from "../images/b2b/hugoBanner.png";
import FooterB2B from "../puresComponents/FooterB2B";

const Search = () => {
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);

  let tmp_tracking_consent = params.get("tracking_consent");
  if (!["false", "true"].includes(tmp_tracking_consent)) {
    tmp_tracking_consent = "false";
  }

  const [focus, setFocus] = useState(false);

  const [
    { searchTracking, resultAutocompletion, bookReco, needLoads },
    dispatch,
  ] = BookConsumerHook();

  useEffect(() => {
    if (bookReco.length > 0 || !needLoads) {
      dispatch({
        type: "addData",
        newBookReco: [],
        newBookSearch: [],
        newBookAuthor: [],
        newBookSerie: [],
        newAuthorRef: [],
        newGlidRef: "",
        newThemeRef: "",
        newSearch: "",
        newTypeOfSearch: "",
        newNeedLoads: true,
      });
    }
  }, []);

  return (
    <>
      {!focus && (
        <>
          <img src={backgroundImage} className="coverHomeIllust" alt="bg-img" />
          <div className="containerTitleSubtitleHome">
            <div className="title">{text.HOME_TITLE}</div>
            <div className="subtitleHome">{text.HOME_SUBTITLE}</div>
          </div>
        </>
      )}
      <section>
        <div style={focus ? { paddingTop: 26 } : null}>
          <Autocompletion
            navigate={navigate}
            searchTracking={searchTracking}
            resultAutocompletion={resultAutocompletion}
            dispatch={dispatch}
            focus={focus}
            setFocus={setFocus}
          />
        </div>
        <div className="separateLineStyle" />
      </section>
      <section>
        <div className="separateLineStyle" />
        <CoupDeCoeur scrollable />
        <div className="separateLineStyle" />
        <FooterB2B />
      </section>
    </>
  );
};

export default Search;
