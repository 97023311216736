import { useNavigate } from "react-router-dom";
import LogoGleeph from "../images/logo_fulltext_grey.svg";
import LogoB2B from "../images/b2b/logoHugo.png";
import cst from "../utils/constant";
import Back from "../images/flecheback_white.svg";

const Header = (props) => {
  var { withBack } = props;
  if (withBack) {
    return <HeaderWithBack />;
  } else {
    return <HeaderHome />;
  }
};

const HeaderWithBack = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className="headerWithBack"
      style={{ backgroundColor: cst.COLOR_TOP_BAR }}
    >
      <div onClick={() => navigate(-1)} className="backHeader">
        <img src={Back} alt="back" className="iconBackHeader" />
      </div>
      <img className="headerLogoB2BWithBack" src={LogoB2B} alt="logo" />
      <span className="headerTextMid">Recommandations par </span>
      <img className="headerLogoB2B" src={LogoGleeph} alt="logoGleeph" />
    </div>
  );
};

const HeaderHome = () => {
  const navigate = useNavigate();
  return (
    <div className="header" style={{ backgroundColor: cst.COLOR_TOP_BAR }}>
      <div onClick={() => navigate(`/${cst.ID_STORE}/`)} className="pointer">
        <img className="headerLogoB2B" src={LogoB2B} alt="logo" />
      </div>
      <img className="headerLogoB2B" src={LogoGleeph} alt="logoGleeph" />
    </div>
  );
};

export default Header;
