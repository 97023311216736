import HeartBookList from "../puresComponents/HeartBookList";
import WorkHorizontalList from "./WorkHorizontalList";
import text from "../utils/lang/lang";
import jaime from "../images/coupDecoeur.svg";
import img_9782755676631 from "../images/b2b/9782755676631.jpg";
import img_9782755673135 from "../images/b2b/9782755673135.jpg";
import img_9782755678628 from "../images/b2b/9782755678628.jpg";
import img_9782755664140 from "../images/b2b/9782755664140.jpg";
import img_9782755678314 from "../images/b2b/9782755678314.png";
import img_9782755677409 from "../images/b2b/9782755677409.jpg";
import img_9782755674163 from "../images/b2b/9782755674163.jpg";
import img_9782755675979 from "../images/b2b/9782755675979.jpg";
import img_9782755673203 from "../images/b2b/9782755673203.jpg";
import img_9782755673593 from "../images/b2b/9782755673593.png";
import img_9782755672404 from "../images/b2b/9782755672404.jpg";
import img_9782755678215 from "../images/b2b/9782755678215.jpg";
import img_9782755672695 from "../images/b2b/9782755672695.jpg";
import img_9782755678727 from "../images/b2b/9782755678727.png";
import img_9782755677676 from "../images/b2b/9782755677676.png";

const CoupDeCoeur = (props) => {
  const { scrollable } = props;
  const heartBook = [
    {
      id: 1,
      id_ean: 9782755676631,
      imgs: img_9782755676631,
      link: "https://www.hugopublishing.fr/produits/paris-2024-le-livre-officiel-des-jeux-olympiques/",
    },
    {
      id: 2,
      id_ean: 9782755673135,
      imgs: img_9782755673135,
      link: "https://www.hugopublishing.fr/produits/the-empyrean-t1-fourth-wing-broche/",
    },
    {
      id: 3,
      id_ean: 9782755678628,
      imgs: img_9782755678628,
      link: "https://www.hugopublishing.fr/produits/memoires-dun-expert-psychiatre-2/",
    },
    {
      id: 4,
      id_ean: 9782755664140,
      imgs: img_9782755664140,
      link: "https://www.hugopublishing.fr/produits/dark-moon-tome-01/",
    },
    {
      id: 5,
      id_ean: 9782755678314,
      imgs: img_9782755678314,
      link: "https://www.hugopublishing.fr/produits/taylor-swift-style/",
    },
    {
      id: 6,
      id_ean: 9782755677409,
      imgs: img_9782755677409,
      link: "https://www.hugopublishing.fr/produits/formule-1-mythiques/",
    },
    {
      id: 7,
      id_ean: 9782755672695,
      imgs: img_9782755672695,
      link: "https://www.hugopublishing.fr/produits/jean-louis-aubert-une-vie-en-chansons/",
    },
    {
      id: 8,
      id_ean: 9782755674163,
      imgs: img_9782755674163,
      link: "https://www.hugopublishing.fr/produits/tous-champions-victor-wembanyama/",
    },
    {
      id: 9,
      id_ean: 9782755675979,
      imgs: img_9782755675979,
      link: "https://www.hugopublishing.fr/produits/lequation-de-la-chauve-souris/",
    },
    {
      id: 10,
      id_ean: 9782755673203,
      imgs: img_9782755673203,
      link: "https://www.hugopublishing.fr/produits/mad-majesty-tome-01/",
    },
    {
      id: 11,
      id_ean: 9782755673593,
      imgs: img_9782755673593,
      link: "https://www.hugopublishing.fr/produits/vapeur-douce/",
    },
    {
      id: 12,
      id_ean: 9782755672404,
      imgs: img_9782755672404,
      link: "https://www.hugopublishing.fr/produits/la-maison-des-aravis/",
    },
    {
      id: 13,
      id_ean: 9782755678727,
      imgs: img_9782755678727,
      link: "https://www.hugopublishing.fr/produits/nymphea-et-la-chambre-rouge-relie-collector/",
    },
    {
      id: 14,
      id_ean: 9782755678215,
      imgs: img_9782755678215,
      link: "https://www.hugopublishing.fr/produits/la-romance-presque-parfaite-dune-accro-a-noel-integrale-collector/",
    },
    {
      id: 15,
      id_ean: 9782755677676,
      imgs: img_9782755677676,
      link: "https://www.hugopublishing.fr/produits/lhistoire-de-lolympique-de-marseille-2/",
    },
  ];

  return (
    <div className="coupdecoeurContainer">
      <div className="coupdecoeurTitleContainer">
        <img src={jaime} alt="icon" className="coupdecoeurIconJaimeContainer" />
        <span className="coupDeCoeurPolice">{text.TITLE_COUP_DE_COEUR}</span>
      </div>
      {heartBook.length > 0 &&
        (scrollable ? (
          <WorkHorizontalList works={heartBook} size={"coupDeCoeur"} />
        ) : (
          <HeartBookList heartBook={heartBook} />
        ))}
    </div>
  );
};

export default CoupDeCoeur;
